import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import ch34 from '../assets/images/Unt.png';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
// import ch344444 from '../assets/images/dollar-bill.png';
// import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import { Carousel } from 'react-responsive-carousel';
import ch343 from '../assets/images/Unt1.jpg';
import ch3444444 from '../assets/images/calendar.png';


import Toyo51 from '../assets/images/moving-black-car-road.jpg';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">ABOUT US</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="">Home</a></li>
                <li className="breadcrumb-item"><a href="#">About Us</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



          <div class="video-banner bg-light">
	                <div class="container align-items-center">
	                	<div class="video-banner-box bg-white">
		                	<div class="row align-items-center">
		                		<div class="col-md-6 mb-3 mb-md-0">
		                			<div class="video-box-content">
                                    <h3 class="video-banner-title h1"><span class="text-primary">About Us </span><strong>Holiday rent a car and tour's</strong></h3>
	                					<b>Holiday rent a car and tour's - Your Gateway to Hassle-Free Travel!
</b>
	                					<p>Welcome to Abbottabad Luxury Rides Tour and Rent a Car, where sophistication meets convenience. Explore our distinguished fleet featuring premier brands such as Honda (Civic, City, BRV, etc.), Toyota (Vigo, Revo, Prado, Fortuner, V8, Corolla, Altis, Yaris, XLI, GLI, etc.), Suzuki (Cultus, Alto, etc.), Mercedes (S-Class, etc.), BMW, and more.
</p>
<p>Our commitment to excellence goes beyond transportation; it's about delivering an elevated travel experience. Whether you desire the elegance of a Mercedes S-Class, the robust performance of a Toyota Fortuner, or the sleek design of a Honda Civic, each vehicle in our fleet is meticulously maintained for your comfort.
 <br />
 Abbottabad Luxury Rides offers premium car rental services with or without a driver, ensuring flexibility tailored to your preferences. With competitive rates and a dedication to professionalism, we redefine luxury travel, making every journey a seamless blend of style and reliability.
 <br />
  
 Choose Abbottabad Luxury Rides for a distinctive travel encounter – where precision, prestige, and unparalleled service converge</p>
	                		
                           
                          
		                			</div>
		                		</div>
		                		<div class="col-md-6">
		                			<div class="video-poster">
		                				<img src={ab1} alt="poster"/>

		                				<div class="video-poster-content">
		                					<a href="https://www.facebook.com/holiday.512" class="btn-video btn-iframe"><i class="icon-play"></i></a>
		                				</div>
		                			</div>
		                		</div>
		                	</div>
	                	</div>
	                </div>
            	</div>






                <div className="container banner-group-1">
               
               <div className='container' style={{background :  "#f0f0f0"}}>
                 <br/>
            <p class="text-center">AFFORDABLE CARS IN PAKISTAN


</p>
            <h2 class="title mb-4 text-center">Why <b>holidayrentacar.com?</b> 

</h2>
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
                                   {/* <i class="icon-local_car_wash"></i> */}
                                   <img src={ch344} alt="" style={{height   : "40px"}}/>
                               </span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Rent a car with and <br /> without a driver
</h3>
                                   <p className="cgg">Options for both self-drive and with driver available



</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                           <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                           <img src={ch3444} alt="" style={{height   : "40px"}}/>
</span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>24/7 Customer Online <br /> Support
</h3>
                                   <p className="cgg" style={{}}>Call us Anywhere Anytime
</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                           <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                           <img src={ch34444} alt="" style={{height   : "40px"}}/>
</span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Clean and safe car
</h3>
                                   <p className="cgg">Efficient safety and cleanliness checks

</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                           <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                           <img src={ch3444444} alt="" style={{height   : "40px"}}/>
</span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Reservation Anytime <br /> You Want 
</h3>
                                   <p className="cgg">24/7 Online Reservation




</p>
                               </div>
                           </div>
                       </div>
                   </div>
</div>







            



               <div className="container">
               <div className="row">
                 <div className="col-12" style={{padding : "0px"}}>
                   <div className="">
                     <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+ch343+')',backgroundRepeat : "no-repeat" ,height:  "410px",backgroundColor : "transparent"}}>
                      
                     </div>
                   </div>
                 </div>
               </div>
             </div>



<div className='container' style={{background :  "#95060D"}}>
         
         <div class="row justify-content-center">
             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                 <img src={ch34444} alt="" style={{height   : "40px"}}/>

                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>30+ 
</h3>
                         <p className="cgg1" style={{}}>NO. OF CARS

</p>
                     </div>
                 </div>
             </div>
             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >
                         {/* <i class="icon-local_car_wash"></i> */}
                         <img src={ch344} alt="" style={{height   : "40px"}}/>
                   
                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 4,000+

</h3>
                         <p className="cgg1">HAPPY CUSTOMERS




</p>
                     </div>
                 </div>
             </div>


             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                 <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 6
</h3>
                         <p className="cgg1">Operate In Locations

</p>
                     </div>
                 </div>
             </div>
             
             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                 <img src={ch3444} alt="" style={{height   : "40px"}}/>
                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 24/7
</h3>
                         <p className="cgg1">Quality Support

</p>
                     </div>
                 </div>
             </div>
             
         </div>
</div>

<br />
</div>






<div class="bg-image bg-overlay pt-5 pb-4" style={{backgroundImage: 'url('+Toyo51+')',backgroundRepeat : "no-repeat" }}>
<br />
            		<div class="container">
            			<h2 class="title text-center text-white mb-3">What Our Clients Say
</h2>
                  <Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>

	                	{/* <div class="owl-carousel owl-theme owl-testimonials owl-light" data-toggle="owl" 
                           > */}
	                		<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Holiday rent a car and tour's was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                		Yasir Arafat

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ I rented 25 days Toyota corola for my needs to travel within the city Karachi. With family. Excellent service. Driver behavior very good. Best rates.

”</p>

		                		<cite>
		                		Haji Nazeer Gujjar

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Holiday rent a car and tour's was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                			Junaid Ghani
		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>
	                	{/* </div> */}
                    </Carousel>

            		</div>
            	</div>
<br />























<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>

            <h2>Welcome to Holiday rent a car and tour's</h2>

<p>Your premier destination for top-notch rental car services. Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models such as the Toyota Corolla and Honda HR-V. As your trusted partner in transportation, we are committed to delivering an unparalleled experience that combines comfort, convenience, and excellence.</p>

<h2>Our Mission</h2>

<p>At Holiday rent a car and tour's, our mission is to redefine the rental car experience through a seamless fusion of quality service and customer satisfaction. Whether it's a business trip or a family vacation, our dedication to providing exceptional services ensures that every journey is not just a trip but a memorable adventure.</p>

<h2>Fleet Excellence</h2>

<p>Experience the pinnacle of performance with our meticulously maintained fleet, showcasing the efficiency of the Toyota Corolla and the style of the Honda HR-V. Our commitment to excellence extends to every vehicle, guaranteeing a smooth and enjoyable ride. Choose Holiday rent a car and tour's for a journey that reflects our passion for quality and precision.</p>

<h2>Pickup and Drop-off Services</h2>

<p>Embrace the convenience of our tailored pickup and drop-off services. At Holiday rent a car and tour's, we understand the value of your time, and our services are designed to make your experience seamless. Whether you prefer doorstep delivery or choose to pick up your vehicle at our location, our flexible options cater to your schedule, ensuring a stress-free rental experience.</p>

<h2>Rental Solutions</h2>

<p>Unlock a world of possibilities with our rental solutions. From short-term daily rentals to long-term leases, Holiday rent a car and tour's provides customizable packages to suit your unique needs. Our commitment to versatility ensures that you have the perfect vehicle for every occasion, allowing you to focus on the journey ahead.</p>

<p>Choose Holiday rent a car and tour's for a rental experience that goes beyond expectations. With our dedication to excellence, a diverse and well-maintained fleet, and services optimized for your convenience, we are your ultimate destination for top-tier transportation solutions. Book with us today and elevate your travel experience to new heights.</p>

            </div>
            </div>
            </div>
            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;