import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import swal from 'sweetalert';

import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const Carousel3 = (handleClick) =>{
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
      const [DataPart2 , setDataPart2] = useState([])
      const [product , setproduct] = useState([])



      useEffect(() =>{


      fetch("https://holiday-rent-a-car-main-back.vercel.app/AllProduct",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res5=>res5.json())
    .then(res6=>{
     const pro = []
     res6.map((res7,i)=>{
       console.log(res7 , res7.Product_Catagories === "Cars")
       if (res7.status && res7.Product_Catagories === "Cars"){
        
             pro.push(res7)
         }
      
     })
   
     setproduct(pro)
   
    })


},[])



const addtocartproduct = (productDetail) =>{
    swal("Update!", "Your Item Add in Cart!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
  const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
    return (

        <Carousel
  swipeable={false}
  draggable={true}
  showDots={false}
  arrows={true}
  responsive={responsive}
  infinite={true}
  minimumTouchDrag={2}
  autoPlay = {true}
  transitionDuration={4500}
//   autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={4500}
  keyBoardControl={true}
  customTransition="all 2s ease-out"
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
//   deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
 




{product.map((res,i)=>{
  if(res.Price[0]){

    return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
          <div className="product product-7 text-center" style={{height : "auto"}}>
            <figure className="product-media">
              <span className="product-label label-new">New</span>
              <Link  to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
              </Link>
              <div className="product-action-vertical">
                <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
              </div>{/* End .product-action-vertical */}
              <div className="product-action">
                <a className="btn-product btn-cart"  onClick={()=>addtocartproduct([res])}><span>add to cart</span></a>
              </div>{/* End .product-action */}
            </figure>{/* End .product-media */}
            <div className="product-body" style={{height:"139px"}}> 
              <div className="product-cat">
                <Link to={"/main-categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
              </div>{/* End .product-cat */}
              <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
              <div className="product-price">
              
                {res.Size_Discounted[0]  ? 
    <><span className="product-price">Rs. {res.Size_Discounted[0]}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Price[0])}</del></span></>
    :
    <span>
    Rs. {(res.Price[0])}
    </span> 
    
                      }   







                       
    
{ res && res.luggage &&  res.luggage > 0 ?
  <div style={{display :"flex",flexDirection : "row",justifyContent :"space-around",padding:"5px 0px",width:"100%",margin:"auto"}}>
  <div>
    <div style={{display:"flex",}}>
      <DirectionsCarIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
  </div>
    <div style={{display:"flex",}}>
      <MeetingRoomIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
  </div>
    <div style={{display:"flex",}}>
      <SupervisorAccountIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.person && res.person} Person</p>
  </div>
    
  </div>
  <div>
    <div style={{display:"flex",}}>
      <WhatshotIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
  </div>
    <div style={{display:"flex",}}>
      <ReceiptIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
  </div>
    <div style={{display:"flex",}}>
      <LocalMallIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} luggage</p>
  </div>
    
  </div>
  </div>
  
  :""
                        
                      }
                    







              </div>{/* End .product-price */}
              <div className="product-nav product-nav-thumbs">
                {res &&res.Product_Image_Upload1
                ?
                <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="active">
                  <img src={res.Product_Image_Upload1} alt="product desc" />
                </Link>
                : ""
                
                }
                {res &&res.Product_Image_Upload2
                ?
                <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >
                  <img src={res.Product_Image_Upload2} alt="product desc" />
                </Link>
                : ""
                
                }
                {res &&res.Product_Image_Upload3
                ?
                <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >
                <img src={res.Product_Image_Upload3} alt="product desc" />
              </Link>
                : ""
                
                }

                
               
              </div>{/* End .product-nav */}
              <div className="product-action position-relative visible">
            <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Add to cart" onClick={()=>addtocartproduct([res])}>
                <span className="text-dark shadow-none">add to cart</span>
              </a>
            </div>
                
            </div>{/* End .product-body */}
          </div>{/* End .product */}
        </div>
)

}
else{
    if(res.Course_Sub_Catagories !== ""){
                return(
<div className="product d-flex flex-column overflow-hidden item">
                          <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          {/* <span className="product-label label-sale">SALE</span> */}

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="w-100">
                              <img src={res.Product_Image_Upload} className="product-image" width={192} height={192} />
                              <img src={res.Product_Image_Upload1} className="product-image-hover" width={192} height={192} />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon text-dark btn-wishlist" title="Add to wishlist" onClick={()=>addtocartproduct8([res])}>
                                <span>add to wishlist</span>
                              </a>
                              {/* <a href="popup/quickView.html" className="btn-product-icon text-dark btn-quickview" title="Quick view">
                                <span>Quick view</span>
                              </a>
                              <a href="#" className="btn-product-icon text-dark btn-compare" title="Compare">
                                <span>Compare</span>
                              </a> */}
                              <a className="btn-product-icon text-dark btn-cart" title="Cart" onClick={()=>addtocartproduct([res])}>
                              <span>add to cart</span>
                              </a>

                            </div>
                            {/* End .product-action-vertical */}
                          </figure>
                          {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                          <div className="product-body" style={{height:"139px"}}> 
                            <div className="product-cat mb-0 text-light text-left">
                              <a>{res.Product_Catagories}</a>
                            </div>
                            {/* End .product-cat  */}
                            <h3 className="product-title letter-spacing-normal font-size-normal mb-0 text-left"  style={{maxWidth:"100%",maxHeight : "46px",overflow : "hidden"}}>
                              <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >{res.Product_Name}</Link>
                            </h3>
                            {/* End .product-title letter-spacing-normal font-size-normal */}
                            {res.Product_Price_Discounted ? 
                            <div className="product-price mb-1">
                        <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                        <div className="old-price font-size-normal font-weight-normal">Rs. {(res.Product_Price)}</div>
                      </div>
                      :
                            <div className="product-price mb-1 text-dark">
                             Rs. {(res.Product_Price)}
                            </div>











            }









                       
    
{ res && res.luggage &&  res.luggage > 0 ?
  <div style={{display :"flex",flexDirection : "row",justifyContent :"space-around",padding:"5px 0px",width:"100%",margin:"auto"}}>
  <div>
    <div style={{display:"flex",}}>
      <DirectionsCarIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
  </div>
    <div style={{display:"flex",}}>
      <MeetingRoomIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
  </div>
    <div style={{display:"flex",}}>
      <SupervisorAccountIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.person && res.person} Person</p>
  </div>
    
  </div>
  <div>
    <div style={{display:"flex",}}>
      <WhatshotIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
  </div>
    <div style={{display:"flex",}}>
      <ReceiptIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
  </div>
    <div style={{display:"flex",}}>
      <LocalMallIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} luggage</p>
  </div>
    
  </div>
  </div>
  
  :""
                        
                      }
                    












                            {/* End .product-price */}
                            
                            {/* End .product-nav */}
                          </div>
                          {/* End .product-body */}
                        </div>

                )
            }
            if(res.Product_Sub_Catagories !== ""){
              console.log("323")
              return(
            
                <div className="product d-flex flex-column overflow-hidden">
                          <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          {/* <span className="product-label label-sale">SALE</span> */}

                            <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="w-100">
                              <img src={res.Product_Image_Upload} className="product-image" width={192} height={192} />
                              <img src={res.Product_Image_Upload1} className="product-image-hover" width={192} height={192} />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon text-dark btn-wishlist" title="Add to wishlist" onClick={()=>addtocartproduct8([res])}>
                                <span>add to wishlist</span>
                              </a>
                              {/* <a href="popup/quickView.html" className="btn-product-icon text-dark btn-quickview" title="Quick view">
                                <span>Quick view</span>
                              </a>
                              <a className="btn-product-icon text-dark btn-compare" title="Compare">
                                <span>Compare</span>
                              </a> */}
                              <a className="btn-product-icon text-dark btn-cart" title="Cart" onClick={()=>addtocartproduct([res])}>
                              <span>add to cart</span>
                              </a>  
                            </div>
                            {/* End .product-action-vertical */}
                          </figure>
                          {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                          <div className="product-body" style={{height:"139px"}}> 
                            <div className="product-cat mb-0 text-light text-left">
                              <a>{res.Product_Catagories}</a>
                            </div>
                            {/* End .product-cat  */}
                            <h3 className="product-title letter-spacing-normal font-size-normal mb-0 text-left"  style={{maxWidth:"100%",maxHeight : "46px",overflow : "hidden"}}>
                              <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >{res.Product_Name}</Link>
                            </h3>
                            {/* End .product-title letter-spacing-normal font-size-normal */}
                            {res.Product_Price_Discounted ? 
                            <div className="product-price mb-1">
                        <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                        <div className="old-price font-size-normal font-weight-normal">Rs. {(res.Product_Price)}</div>
                      </div>
                      :
                            <div className="product-price mb-1 text-dark">
                             Rs. {(res.Product_Price)}
                            </div>
            }

            







                       
    
{ res && res.luggage &&  res.luggage > 0 ?
  <div style={{display :"flex",flexDirection : "row",justifyContent :"space-around",padding:"5px 0px",width:"100%",margin:"auto"}}>
  <div>
    <div style={{display:"flex",}}>
      <DirectionsCarIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
  </div>
    <div style={{display:"flex",}}>
      <MeetingRoomIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
  </div>
    <div style={{display:"flex",}}>
      <SupervisorAccountIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.person && res.person} Person</p>
  </div>
    
  </div>
  <div>
    <div style={{display:"flex",}}>
      <WhatshotIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
  </div>
    <div style={{display:"flex",}}>
      <ReceiptIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
  </div>
    <div style={{display:"flex",}}>
      <LocalMallIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} luggage</p>
  </div>
    
  </div>
  </div>
  
  :""
                        
                      }
                    


            
                            {/* End .product-price */}
                            
                            {/* End .product-nav */}
                          </div>
                          {/* End .product-body */}
                        </div>
                                )
            }
            else{
              return(
                <div className="product d-flex flex-column overflow-hidden">
                <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                {/* <span className="product-label label-sale">SALE</span> */}

                  <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="w-100">
                  <img src={res.Product_Image_Upload} className="product-image" width={192} height={192} />
                              <img src={res.Product_Image_Upload1} className="product-image-hover" width={192} height={192} />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon text-dark btn-wishlist" title="Add to wishlist" onClick={()=>addtocartproduct8([res])}>
                                <span>add to wishlist</span>
                              </a>
                              {/* <a href="popup/quickView.html" className="btn-product-icon text-dark btn-quickview" title="Quick view">
                                <span>Quick view</span>
                              </a>
                              <a className="btn-product-icon text-dark btn-compare" title="Compare">
                                <span>Compare</span>
                              </a> */}
                              <a className="btn-product-icon text-dark btn-cart" title="Cart" onClick={()=>addtocartproduct([res])}>
                              <span>add to cart</span>
                              </a>

                            </div>
                  {/* End .product-action-vertical */}
                </figure>
                {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                <div className="product-body" style={{height:"139px"}}> 
                  <div className="product-cat mb-0 text-light text-left">
                    <a>{res.Product_Catagories}</a>
                  </div>
                  {/* End .product-cat  */}
                  <h3 className="product-title letter-spacing-normal font-size-normal mb-0 text-left"  style={{maxWidth:"100%",maxHeight : "46px",overflow : "hidden"}}>
                    <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} >{res.Product_Name}</Link>
                  </h3>
                  {/* End .product-title letter-spacing-normal font-size-normal */}
                  {res.Product_Price_Discounted ? 
                            <div className="product-price mb-1">
                        <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                        <div className="old-price font-size-normal font-weight-normal">Rs. {(res.Product_Price)}</div>
                      </div>
                      :
                            <div className="product-price mb-1 text-dark">
                             Rs. {(res.Product_Price)}
                            </div>
            }


                       
    
{ res && res.luggage &&  res.luggage > 0 ?
  <div style={{display :"flex",flexDirection : "row",justifyContent :"space-around",padding:"5px 0px",width:"100%",margin:"auto"}}>
  <div>
    <div style={{display:"flex",}}>
      <DirectionsCarIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
  </div>
    <div style={{display:"flex",}}>
      <MeetingRoomIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
  </div>
    <div style={{display:"flex",}}>
      <SupervisorAccountIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.person && res.person} Person</p>
  </div>
    
  </div>
  <div>
    <div style={{display:"flex",}}>
      <WhatshotIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
  </div>
    <div style={{display:"flex",}}>
      <ReceiptIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
  </div>
    <div style={{display:"flex",}}>
      <LocalMallIcon style={{color: "#ca8b0a",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} luggage</p>
  </div>
    
  </div>
  </div>
  
  :""
                        
                      }
                  {/* End .product-price */}
                
                  {/* End .product-nav */}
                </div>
                {/* End .product-body */}
              </div>
                
                                )
            }
}
          
              
              })
                
              }
          
</Carousel>

    )
}

export default Carousel3